import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'


const SectionTemplate = ({
    name,
    description,
    children,
    expandedSection,
    onExpandedSectionChange,
}) => {
    const [display, setDisplay] = useState(false)

    useEffect(() => {
        if (expandedSection === name) {
            setDisplay(true)
        }

        if (expandedSection && expandedSection !== name) {
            setDisplay(false)
        }

        if (!expandedSection && display) {
            const timeout = setTimeout(() => {
                setDisplay(false)
                clearTimeout(timeout)
            }, 500)
        }
    }, [expandedSection, display])

    if (!display) {
        return null
    }

    const handleMouseLeave = (event) => {
        if (onExpandedSectionChange) {
            onExpandedSectionChange()(event)
        }
    }

    return (
        <div className="header-element flex -mx-8 pt-4 md:pt-0 px-4 md:px-16 md:pb-4 flex-col md:flex-row" onMouseLeave={handleMouseLeave}>
            <div style={{ flex: '1 0 calc(25% - 4rem)' }} className="flex mx-8 my-4 md:m-8">
                <div>
                    <h2 className="border-b-4 border-primary inline-block text-xl font-bold">{name}</h2>
                    <p className="text-sm mt-4 md:mb-16">{description}</p>
                </div>
            </div>
            {children}
        </div>
    )
}

SectionTemplate.propTypes = {
    actionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    expandedSection: PropTypes.string,
    onExpandedSectionChange: PropTypes.func,
    onAction: PropTypes.func,
}

SectionTemplate.defaultProps = {
    expandedSection: null,
    onAction: null,
    onExpandedSectionChange: null,
}

export default SectionTemplate
